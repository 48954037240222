import GET_ARTICLES from "../class/getArticles";

const [ w, d ] = [window, document];
const domReady = TPL => {
  new BLOCKS;
  let Mv = document.querySelector('.mainvisual');
  w.addEventListener('load', function() {
    Mv.classList.add('loaded');
  });
};

class BLOCKS {
  constructor() {
    this.headerBg();
    this.news();
  }

  headerBg () {
    const windowH = $(w).height();
    $(w).on('load scroll', function(){
      let scroll = $(this).scrollTop();
      if ( scroll >= windowH) {
        $('header').removeClass('nobg');
      } else {
        $('header').addClass('nobg');
      }
    });
  }

  news () {
    // mainvisual news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_wrap[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
    <a href="${item.href}">
      <p class="info">
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
      </p>
      <p class="ttl">${item.title}</p>
    </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();
    
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
    <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
    <figure><img src="${item.thumb}" alt="${item.title}"></figure>
    <div class="text_wrap">
    <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
    <p class="ttl"><span>${item.title}</span></p>
    </div>
    </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
    
    // カテゴリー
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    getCategories.then( cats => {
      let cat = {
        all: {
          id: 'all', href: '/news/?mode=list', name: 'ALL', turn: 0
        },
        other: {
          id: 'other', href: '/news/?mode=list&cat=0', name: 'その他', turn: 99
        }
      }
      cats = [ cat.all, ...cats, cat.other ];
      const makeHTML = cat => `<li class="block-news__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></li>`;
      categoryContainer.forEach( obj => {
        obj.innerHTML = '';
        cats.forEach( cat => obj.insertAdjacentHTML('beforeend', makeHTML(cat) ) );
      });
    });
  }
  
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}


const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}