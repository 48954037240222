import GET_ARTICLES from "../class/getArticles";

const [d] = [document];
const domReady = TPL => {
  // Promise.all([window.load['recruit']]).then( () => new recruitBLOCK );
  new recruitBLOCK;
  $(function(){
    const blockLead = document.querySelector('.block-lead');
    blockLead.classList.add('loaded');
  })
};

class recruitBLOCK {
  constructor() {
    this.recruit();
  }

  recruit () {
    const getArticle = new GET_ARTICLES('.block-guideline .recruit_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a href="${item.href}">
        <figure><img src="${item.thumb}" alt="${item.title}"></figure>
        <dl>
          <dt>${item.title}</dt>
          <dd><span>${item.desc}</span></dd>
        </dl>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);

    getArticle.render();
  }
}

const jqInit = () => false;

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}